import { CheckIcon, HandThumbUpIcon, UserIcon } from "@heroicons/react/24/solid"
import React, { Component } from "react"
import Transaction from "../../images/home_transaction.png"
import { Language } from "../../store/reducers/utilsReducer"
import Feeds, { Feed } from "../base/Feeds"

const feeds: Feed[] = [
  {
    iconClass: "bg-gray-400",
    iconType: <UserIcon className="h-5 w-5 text-white" />,
    content: "Sign up a Free Account and Start Collecting Used Cooking Oil",
    step: "Step 1",
  },
  {
    iconClass: "bg-blue-500",
    iconType: <HandThumbUpIcon className="h-5 w-5 text-white" />,
    content: "Notify Arus Oil logistic team once you are ready to sell",
    step: "Step 2",
  },
  {
    iconClass: "bg-arusgreen-500",
    iconType: <CheckIcon className="h-5 w-5 text-white" />,
    content: "View payment made to you on Arus Oil App",
    step: "Step 3",
  },
]

const feedsBM: Feed[] = [
  {
    iconClass: "bg-gray-400",
    iconType: <UserIcon className="h-5 w-5 text-white" />,
    content: "Daftar Akaun Percuma dan mula mengumpulkan Minyak Masak Terpakai",
    step: "Langkah 1",
  },
  {
    iconClass: "bg-blue-500",
    iconType: <HandThumbUpIcon className="h-5 w-5 text-white" />,
    content:
      "Maklumkan kepada pasukan logistik Arus Oil setelah anda bersedia menjual",
    step: "Langkah 2",
  },
  {
    iconClass: "bg-arusgreen-500",
    iconType: <CheckIcon className="h-5 w-5 text-white" />,
    content: "Lihat pembayaran yang dibuat kepada anda di aplikasi Arus Oil",
    step: "Langkah 3",
  },
]

const feedsCH: Feed[] = [
  {
    iconClass: "bg-gray-400",
    iconType: <UserIcon className="h-5 w-5 text-white" />,
    content: "注册一个免费帐户并开始收集用过的食用油",
    step: "第一步",
  },
  {
    iconClass: "bg-blue-500",
    iconType: <HandThumbUpIcon className="h-5 w-5 text-white" />,
    content: "准备好出售后通知 Arus Oil 的物流团队",
    step: "第二步",
  },
  {
    iconClass: "bg-arusgreen-500",
    iconType: <CheckIcon className="h-5 w-5 text-white" />,
    content: "查看在 Arus Oil 应用程序上向您支付的款项",
    step: "第三步",
  },
]

interface Props {
  language: Language
}

export default class Features extends Component<Props> {
  renderFeaturesContent = () => {
    if (this.props.language === Language.EN) {
      return (
        <>
          <h3 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Get Started With 3 Simple Steps
          </h3>
          <p className="mt-7 leading-7 text-base text-justify text-gray-500">
            Arus Oil is an on-demand recycling platform for used cooking oil
            that enables household and businesses to recycle their used cooking
            oil conveniently.
          </p>

          <p className="mt-7 leading-7 text-base text-justify text-gray-500">
            Our digital solution creates a structure within this informal market
            by tracking the supply chain as a prove of origin of the material
            where all information is available remotely via the Arus Oil
            application. Our platform is transparent and accountable – providing
            all stakeholders peace of mind and advancements within their
            day-to-day process.
          </p>

          <dl className="mt-10 space-y-10">
            <div className="flow-root">
              <Feeds content={feeds} />
            </div>
          </dl>
        </>
      )
    } else if (this.props.language === Language.BM) {
      return (
        <>
          <h3 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Mulakan Dengan 3 Langkah Mudah
          </h3>
          <p className="mt-7 leading-7 text-base text-justify text-gray-500">
            Arus Oil adalah platform kitar semula minyak masak terpakai yang
            membolehkan isi rumah dan perniagaan mengitar semula minyak masak
            terpakai mereka dengan mudah.
          </p>

          <p className="mt-7 leading-7 text-base text-justify text-gray-500">
            Penyelesaian digital kami mewujudkan struktur dalam pasaran informal
            ini dengan mengesan rantaian bekalan sebagai bukti asal bahan di
            mana semua maklumat tersedia dari jauh melalui aplikasi Arus Oil.
            Platform kami telus dan bertanggungjawab - memberikan ketenangan
            fikiran dan kemajuan kepada semua pihak dalam proses mereka
            sehari-hari.
          </p>

          <dl className="mt-10 space-y-10">
            <div className="flow-root">
              <Feeds content={feedsBM} />
            </div>
          </dl>
        </>
      )
    } else {
      return (
        <>
          <h3 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            从 3 个简单步骤开始
          </h3>
          <p className="mt-7 leading-7 text-base text-justify text-gray-500">
            Arus Oil
            是一个使用过的食用油回收平台，可让家庭和企业轻松回收使用过的食用油。
          </p>

          <p className="mt-7 leading-7 text-base text-justify text-gray-500">
            我们的数字解决方案通过跟踪供应链作为材料来源的证明，在这个非正式市场中创建结构，其中所有信息都可以通过
            Arus Oil
            应用程序远程获得。我们的平台是透明且负责任的——让所有各方在日常流程中都能安心并取得进展。
          </p>

          <dl className="mt-10 space-y-10">
            <div className="flow-root">
              <Feeds content={feedsCH} />
            </div>
          </dl>
        </>
      )
    }
  }

  render() {
    return (
      <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
        <div className="relative max-w-md mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl sm:max-w-3xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width="404"
            height="784"
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="784"
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">{this.renderFeaturesContent()}</div>

            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width="784"
                height="404"
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="784"
                  height="404"
                  fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                />
              </svg>
              <img
                className="relative mx-auto"
                width="300"
                src={Transaction}
                alt=""
              />
            </div>
          </div>

          <svg
            className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
            width="404"
            height="784"
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="784"
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
        </div>
      </div>
    )
  }
}
