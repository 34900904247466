import {
  CalendarDaysIcon,
  ChartPieIcon,
  MegaphoneIcon,
} from "@heroicons/react/24/outline"
import { ButtonGroup } from "blixify-ui-web/lib"
import { Link } from "gatsby"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Pricing } from "../../models/Pricing"
import { handleGetPricingData } from "../../store/actions/promotionActions"
import {
  Language,
  utilsStateInterface,
} from "../../store/reducers/utilsReducer"
import { localeContent } from "../../utils/locale"
import Button from "../base/Button"
import Rates, { Rate, Title } from "../base/Rates"

interface Props {
  language: Language
  utilsStore: utilsStateInterface
}

interface State {
  selectedPricing: string
  pricingData: Pricing[]
}

class PricingPage extends Component<Props> {
  state: State = {
    selectedPricing: "Door to Door",
    pricingData: [
      {
        type: "Door to Door",
        tierPrice: {
          tier1: 0,
          tier2: 0,
          tier3: 0,
          tier4: 0,
        },
      },
    ],
  }

  componentDidMount() {
    this.handleGetPricing()
  }

  handleGetPricing = async () => {
    let pricingCollection: any[] = []
    let pricingData = await handleGetPricingData(this.props.utilsStore.firebase)

    if (pricingData) {
      pricingData.forEach((eachPricing: any) => {
        pricingCollection.push(eachPricing)
      })
    }

    this.setState({
      pricingData: pricingCollection,
    })
  }

  handleSetID = (id: string) => {
    this.setState({
      selectedPricing: id,
    })
  }

  renderRates = () => {
    const weight = [10, 50, 100, 100]
    const rates: Rate[] = []
    this.state.pricingData.map(eachPrice => {
      if (eachPrice.type === this.state.selectedPricing) {
        const objectPriceList = Object.entries(eachPrice.tierPrice).filter(
          eachObj => {
            const [key, value] = eachObj
            if (value !== null && key.startsWith("tier")) return value
            else return null
          }
        )
        objectPriceList.sort((a, b) => {
          const tierANumber = parseInt(a[0].replace("tier", ""), 10)
          const tierBNumber = parseInt(b[0].replace("tier", ""), 10)
          return tierANumber - tierBNumber
        })

        objectPriceList.map((eachPriceValue, index) => {
          rates.push({
            price: eachPriceValue[1],
            type:
              eachPrice.type === "Collection"
                ? "events"
                : index < objectPriceList.length
                ? "lesser"
                : "equal-more",
            weight: weight[index],
          })
          return null
        })
      }
      return eachPrice
    })
    return rates
  }

  renderPricingTitle = () => {
    if (this.props.language === Language.EN) {
      return "Selling Rates of Your UCO"
    } else if (this.props.language === Language.BM) {
      return "Harga Jualan Minyak Masak Terpakai Anda"
    } else {
      return "您的 UCO 的销售率"
    }
  }

  renderButtonDoorToDoor = () => {
    if (this.props.language === Language.EN) {
      return "Collect From Your Home"
    } else if (this.props.language === Language.BM) {
      return "Ambil Dari Rumah Anda"
    } else {
      return "上门收取"
    }
  }

  renderButtonCollection = () => {
    if (this.props.language === Language.EN) {
      return "Bring To Our Collection Events"
    } else if (this.props.language === Language.BM) {
      return "Bawa Ke Tempat Pengumpulan Kami"
    } else {
      return "带到我们的收集地点"
    }
  }

  renderFAQLink = () => {
    if (this.props.language === Language.CH) {
      return "/ch/faqs"
    } else if (this.props.language === Language.BM) {
      return "/bm/faqs"
    } else {
      return "/faqs"
    }
  }

  render() {
    const language = this.props.language
    const title: Title = {
      text: this.renderPricingTitle(),
      style: {
        fontSize: "18px",
        fontFamily: undefined,
      },
    }

    return (
      <div className="py-16 mt-16 bg-gray-50 overflow-hidden lg:py-24">
        <div className="relative max-w-md mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl sm:max-w-3xl">
          <div className="relative mt-12 sm:mt-16 lg:mt-24">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-24 lg:items-center">
              <div className="lg:col-start-2">
                <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                  {localeContent(language).pricing.paragraph1}
                </h3>
                <p className="mt-3 text-lg text-gray-500">
                  {localeContent(language).pricing.paragraph2}
                </p>

                <dl className="mt-10 space-y-10">
                  <div className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-arusgreen-500 text-white">
                        <ChartPieIcon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {localeContent(language).pricing.subtitle1}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {localeContent(language).pricing.subtitle2}
                    </dd>
                  </div>

                  <div className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-arusgreen-500 text-white">
                        <MegaphoneIcon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {localeContent(language).pricing.subtitle3}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {localeContent(language).pricing.content}
                    </dd>
                  </div>

                  <div className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-arusgreen-500 text-white">
                        <CalendarDaysIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {localeContent(language).pricing.subtitle4}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {localeContent(language).pricing.description}
                    </dd>
                  </div>
                </dl>

                <Link to={this.renderFAQLink()}>
                  <Button
                    className="mt-10 mb-4 w-full sm:max-w-xs"
                    type="normal"
                    text={localeContent(language).pricing.button}
                  />
                </Link>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                <div className="flex m-3 sm:mb-5 sm:justify-end">
                  <ButtonGroup
                    selection={[
                      {
                        id: "Door to Door",
                        name: this.renderButtonDoorToDoor(),
                      },
                      { id: "Collection", name: this.renderButtonCollection() },
                    ]}
                    onClick={this.handleSetID}
                    selectedId={this.state.selectedPricing}
                  />
                </div>
                <Rates
                  content={this.renderRates()}
                  title={title}
                  fontSize="16px"
                  height={350}
                  language={this.props.language}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
  }
}

export default connect(mapStateToProps)(PricingPage)
